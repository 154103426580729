import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getImageWithTooltips } from "../services/api";
import TooltipModal from "./TooltipModal";
import screenfull from "screenfull";
import "./css/EmbeddedView.css";

function EmbeddedView() {
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const [selectedTooltip, setSelectedTooltip] = useState(null);
  const [showRotateMessage, setShowRotateMessage] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = React.useRef(null);
  const [hasShownRotateMessage, setHasShownRotateMessage] = useState(false);
  const [portraitCount, setPortraitCount] = useState(0);



  useEffect(() => {
    const fetchImage = async () => {
      const data = await getImageWithTooltips(imageId);
      setImage(data);
    };
    fetchImage();

    const handleFullscreenChange = () => {
      setIsFullscreen(
        !!document.fullscreenElement || !!document.webkitFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange); // For Safari

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
    };
  }, [imageId]);

  const handleTooltipClick = (e, tooltip) => {
    e.stopPropagation();
    setSelectedTooltip(tooltip);
  };
  // const toggleFullscreen = () => {
  //   if (!isFullscreen) {
  //     if (containerRef.current.requestFullscreen) {
  //       containerRef.current.requestFullscreen();
  //     } else if (containerRef.current.webkitRequestFullscreen) {
  //       containerRef.current.webkitRequestFullscreen(); // For Safari
  //     } else if (containerRef.current.msRequestFullscreen) {
  //       containerRef.current.msRequestFullscreen(); // IE/Edge
  //     } else {
  //       console.error("Fullscreen API is not supported on this browser.");
  //     }
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen();
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen(); // For Safari
  //     }
  //   }
  // };
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Enter fullscreen
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen(); // For Safari
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen(); // For IE/Edge
      } else {
        console.error("Fullscreen API is not supported on this browser.");
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // For Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // For IE/Edge
      } else {
        console.error("Fullscreen exit is not supported on this browser.");
      }
    }
  };
  
  useEffect(() => {
    const checkOrientation = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  
      if (isMobile && !hasShownRotateMessage) {
        setShowRotateMessage(isPortrait);
      }
    };
  
    const handleOrientationChange = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile && !hasShownRotateMessage) {
        setHasShownRotateMessage(true); // Mark as shown after first rotation
        setShowRotateMessage(false); // Hide the message after rotation
      }
    };
  
    // Initial check
    checkOrientation();
  
    // Add event listeners
    window.addEventListener("resize", checkOrientation);
    window.addEventListener("orientationchange", handleOrientationChange);
  
    return () => {
      window.removeEventListener("resize", checkOrientation);
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [hasShownRotateMessage]);
  
  const handleCloseRotateMessage = () => {
    setShowRotateMessage(false);
    setHasShownRotateMessage(true); // Ensure it won't show again until a refresh
  };
  
  if (!image) return <p>Loading...</p>;


  return (
    <div
      className="embedded-container"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
      }}
      ref={containerRef}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "100vw",
          maxHeight: "100vh",
        }}
      >
        <button
        className="fullscreen-button"
          onClick={toggleFullscreen}
    
          aria-label={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
        >
          {isFullscreen ? (
            // Exit Fullscreen SVG Icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              width="2rem"
              height="2rem"
            >
              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z" />
              {/* <path d="M14 10V5h5v2h-3v3h-2zm-4 0H7V7H5v5h5v-2zm8 8h-3v-3h-2v5h5v-2zm-8 0H5v-2h3v-3h2v5z" /> */}
            </svg>
          ) : (
            // Enter Fullscreen SVG Icon
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              width="24px"
              height="24px"
            >
              <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
            </svg>
          )}
        </button>
        <img
          src={image.url}
          alt="Shared"
          style={{
            width: "100%",
            height: "auto",
            objectFit: "contain",
            display: "block",
          }}
        />
        {image.tooltips?.map((tooltip, i) => (
          <div
            key={i}
            onClick={(e) => handleTooltipClick(e, tooltip)}
            style={{
              color: `${image.color}` || "black",
              position: "absolute",
              left: `${tooltip.x}%`,
              top: `${tooltip.y}%`,
              cursor: "pointer",
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={{
                fontSize:
                  image.size === "small"
                    ? "0.75vw"
                    : image.size === "medium"
                    ? "1vw"
                    : image.size === "large"
                    ? "1.25vw"
                    : "1.25vw",
              }}
            >
              {image.iconStyle || "radio_button_checked"}
            </span>
          </div>
        ))}
        {selectedTooltip && (
          <TooltipModal
            tooltip={selectedTooltip}
            imageUrl={image.url}
            onClose={() => setSelectedTooltip(null)}
            isEditMode={false}
          />
        )}
      </div>
      {showRotateMessage && (
  <div style={{
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 9999,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  }}>
    <button 
      onClick={handleCloseRotateMessage}
      style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        background: 'none',
        border: 'none',
        color: 'white',
        fontSize: '24px',
        cursor: 'pointer'
      }}
    >
      ✕
    </button>
    <span
      className="material-symbols-outlined rotate-animation"
      style={{
        fontSize: "48px",
        marginBottom: "16px",
      }}
    >
      screen_rotation
    </span>
    <p>Please rotate your device</p>
  </div>
)}
    </div>
  );
}

export default EmbeddedView;
