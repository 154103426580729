// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Link,
//   useLocation,
//   Navigate,
// } from "react-router-dom";
// import Home from "./pages/Home";
// import Upload from "./pages/Upload";
// import EditImage from "./pages/EditImage";
// import ShareImage from "./pages/ShareImage";
// import "./App.css";
// import LoginForm from "./pages/LoginForm";
// import EmbeddedView from "./components/EmbeddedView";
// import StaticImageView from "./components/StaticImageView";
// import TooltipEditor from "./components/ToolTipEditor";

// const ProtectedRoute = ({ children }) => {
//   const accessToken = localStorage.getItem("accessToken");

//   if (!accessToken) {
//     return <Navigate to="/" replace />;
//   }

//   return children;
// };

// function App() {
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const isEmbed = searchParams.has('embed');
//   return (
//     <div className={isEmbed?"embed":""}>

//     <Router>
//       {/* <Navigation /> */}
//       <Routes>
//         <Route path="/" element={<LoginForm />} />
//         <Route
//           path="/home"
//           element={
//             <ProtectedRoute>
//               <Home />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/upload"
//           element={
//             <ProtectedRoute>
//               <Upload />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/edit/:imageId"
//           element={
//             <ProtectedRoute>
//               <EditImage />
//             </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/share/:imageId"
//           element={
//             // <ProtectedRoute>
//             <ShareImage />
//             // </ProtectedRoute>
//           }
//         />
//         <Route
//           path="/tooltip/edit/:tooltipId"
//           element={
//             <ProtectedRoute>
//               <TooltipEditor />
//             </ProtectedRoute>
//           }
//         />

//         <Route path="/embed/:imageId" element={<EmbeddedView />} />
//         <Route path="/static/:imageId" element={<StaticImageView />} />
//       </Routes>
//     </Router>
//     </div>
//   );
// }

// export default App;



import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Upload from "./pages/Upload";
import EditImage from "./pages/EditImage";
import ShareImage from "./pages/ShareImage";
import "./App.css";
import LoginForm from "./pages/LoginForm";
import EmbeddedView from "./components/EmbeddedView";
import StaticImageView from "./components/StaticImageView";
import TooltipEditor from "./components/ToolTipEditor";

const ProtectedRoute = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return <Navigate to="/" replace />;
  }

  return children;
};

function AppContent() {
  const location = useLocation();
  const isEmbed = location.pathname.includes("/embed/");
  const isStatic = location.pathname.includes("/static/");


  console.log("pathname:", location.pathname);
  console.log("isEmbed:", isEmbed);
  useEffect(() => {
    if (isEmbed || isStatic) {
      document.body.classList.add("embed");
    } else {
      document.body.classList.remove("embed");
    }
  
    // Cleanup on unmount or route change
    return () => {
      document.body.classList.remove("embed");
    };
  }, [isEmbed]);
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <Upload />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:imageId"
          element={
            <ProtectedRoute>
              <EditImage />
            </ProtectedRoute>
          }
        />
        <Route path="/share/:imageId" element={<ShareImage />} />
        <Route
          path="/tooltip/edit/:tooltipId"
          element={
            <ProtectedRoute>
              <TooltipEditor />
            </ProtectedRoute>
          }
        />
        <Route path="/embed/:imageId" element={<EmbeddedView />} />
        <Route path="/static/:imageId" element={<StaticImageView />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
