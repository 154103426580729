
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import { deleteTooltip } from '../services/api';
import "./css/ToolTipModal.css"


function TooltipModal({ tooltip, imageUrl, onClose, onDelete, imageId, isEditMode = false }) {
    const navigate = useNavigate();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const handleEdit = () => {
        navigate(`/tooltip/edit/${tooltip._id}`, { state: { tooltip, imageId } });
    };
    const confirmDelete = async () => {
        setShowConfirmModal(false); // Close confirmation modal
        await deleteTooltip(tooltip._id); // Execute the passed delete handler
        onDelete();
        onClose();
    };
    const handleDelete = () => {
        setShowConfirmModal(true); // Show confirmation modal
    };
    const isMobile = window.innerWidth <= 800;
    const responsiveStyles = isMobile ? mobileStyles : {};
    const descriptionPoints = tooltip
        ? tooltip.description.split('\n') // Split by new lines, keeping all lines intact
        : [];
    useEffect(() => {
        console.log("tooltip data is", tooltip.innerImage);
    })
    return (
        <>
            <div style={modalStyle}>
                <div className='modalContentStyle'>
                    <button style={closeButtonStyle} onClick={onClose}>
                        <b>X</b>
                    </button>
                    <div className='contentContainerStyle'>
                        {tooltip.innerImage ? (
                            <img src={`${tooltip.innerImage}`} alt="Uploaded Tooltip" style={imageStyle}  className='imageStyle' />
                        ) : (
                            <p>No image provided</p>
                        )}
                        <div style={infoStyle} className='infoStyle'>
                            <div className='titleContainerStyle'>
                                <h2 style={titleStyle} className='titleStyle'>{tooltip.title}</h2>
                                <h4 style={subtitleStyle} className='subtitleStyle'>{tooltip.subtitle}</h4>
                            </div>
                            <div style={descriptionContainerStyle} className="hide-scrollbar">
                                <ul  className='descriptionListStyle'>
                                    {descriptionPoints.map((point, index) => (
                                        <li key={index} style={descriptionItemStyle}>
                                            {point.trim() === "" ? <br /> : point}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div style={linkContainerStyle} className='linkContainerStyle'>
                                <a
                                    href={tooltip.links[0].startsWith('http') ? tooltip.links[0] : `https://${tooltip.links[0]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    
                                    className='linkStyle'   
                                >
                                    More Info
                                </a>
                                <a
                                    href={tooltip.links[1].startsWith('http') ? tooltip.links[1] : `https://${tooltip.links[1]}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='linkStyle' 
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                    {isEditMode && (
                        <div style={buttonContainerStyle}>
                            <button style={deleteButtonStyle} onClick={handleDelete}>Delete</button>
                            <button style={editButtonStyle} onClick={handleEdit}>Edit</button>
                        </div>
                    )}
                </div>
            </div>
            {showConfirmModal && (
                <ConfirmationModal
                    message="Are you sure you want to delete this tooltip?"
                    onConfirm={confirmDelete}
                    onCancel={() => setShowConfirmModal(false)}
                />
            )}
        </>
    );
}


const fadeInAnimation = {
    opacity: 0,
    animation: 'fadeIn 0.75s ease-in forwards',
};

const slideFromLeft = {
    opacity: 0,
    animation: 'slideFromLeft 0.75s ease-in forwards',
};

const slideFromRight = {
    opacity: 0,
    animation: 'slideFromRight 0.75s ease-in forwards',
};

const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const closeButtonStyle = {
    position: 'absolute',
    top: '-14px',
    right: '-22px',
    cursor: 'pointer',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    // fontWeight: 'bold',
    // fontSize: "20px",
    // fontFamily: 'Archivo',
    width: "35.5px",          // Add width
    height: "35.5px",         // Add height
    // fontSize: "15px",       // Increase font size
    display: "flex",        // Center the X
    alignItems: "center",   // Center the X
    justifyContent: "center" // Center the X
};
const contentContainerStyle = {
    display: 'flex',
    gap: '1rem',
    height: '97%',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        gap: '15px'
    }
    // alignItems: 'center',
};
const imageStyle = {
    width: '60%',
    // minHeight: '400px',
    // maxHeight: '80vh',
    // height: '500px', // Fixed height
    objectFit: 'contain',
    // borderRadius: '8px',
    '@media (max-width: 768px)': {
        width: '100%',
        height: '200px'
    }
};
const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.2rem",
};
const editButtonStyle = {
    backgroundColor: "Transparent",
    color: "#007BFF",
    border: "none",
    // borderRadius: "4px",
    // padding: "10px 20px",
    cursor: "pointer",
};
const deleteButtonStyle = {
    backgroundColor: "Transparent",
    color: "#DC3545",
    border: "none",
    // borderRadius: "4px",
    // padding: "10px 20px",
    cursor: "pointer",
};
const infoStyle = {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Changed from space-evenly
    // height: '500px', // Added to ensure full height usage
    '@media (max-width: 768px)': {
        width: '100%',
        minHeight: 'auto',
        padding: '10px'
    }
};

const titleStyle = {
    ...fadeInAnimation,
    textAlign: "left",
    marginTop: '0px',    // Added to ensure no extra space from top
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '5px',  // Reduced from 10px
    fontFamily: 'Archivo',
};
const subtitleStyle = {
    ...fadeInAnimation,
    textAlign: "left",
    fontSize: '21px',
    color: '#555',
    marginTop: '0px',    // Added to ensure no extra space from top
    marginBottom: '3rem',  // Keep this the same to maintain spacing with description
    fontFamily: 'Archivo',

};
const descriptionContainerStyle = {
    ...fadeInAnimation,
    flex: 1,
    overflowY: 'auto',
    marginBottom: '0.5rem',
    msOverflowStyle: 'none',  // IE and Edge
    scrollbarWidth: 'none',   // Firefox
    '&::-webkit-scrollbar': { // Chrome, Safari and Opera
        display: 'none'
    }
};
const descriptionStyle = {
    textAlign: "left",
    fontSize: '16px',
    marginBottom: '10rem',
};
const linkContainerStyle = {
    ...fadeInAnimation,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    marginTop: 'auto',
    textAlign: 'left',
    marginBottom: '2rem',
    fontStyle: 'italic'
};
const linkStyle = {
    marginBottom: 0,
    fontSize: '17px',
    color: '#007BFF',
    fontStyle: 'italic',
    // textDecoration: 'none',
};
const mobileStyles = {
    modalContentStyle: {
        width: '95%',
        padding: '15px',
        height:"500px"
    },
    contentContainerStyle: {
        flexDirection: 'column',
        gap: '15px',
    },
    imageStyle: {
        width: '100%',
        minHeight: '200px',
    },
    infoStyle: {
        width: '100%',
        minHeight: 'auto',
        padding: '10px',
    },
    linkContainerStyle: {
        marginTop: '1.5rem',
    }
};
const descriptionListStyle = {
    textAlign: "left",
    fontSize: "18px",
    // marginBottom: "1rem",
    paddingLeft: "0rem",
    listStyleType: "disc",
    margin: 0,
    lineHeight: "2rem"
};
const descriptionItemStyle = {
    marginBottom: "0.5rem",
    listStyleType: "none"
};
export default TooltipModal;
