// frontend/src/pages/ShareImage.js
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { getImageWithTooltips } from "../services/api";
import "./css/SharedImage.css";
import TooltipModal from "../components/TooltipModal";
// import FullscreenIcon from '@mui/icons-material/Fullscreen';

function ShareImage() {
  const { imageId } = useParams();
  const [image, setImage] = useState(null);
  const location = useLocation();
  const [selectedTooltip, setSelectedTooltip] = useState(null);
  const [copySuccess, setCopySuccess] = useState("");
  const [copySuccessLink, setCopySuccessLink] = useState("");
  const [copySuccessEmbed, setCopySuccessEmbed] = useState("");
  const shareableUrl = `${window.location.origin}/share/${imageId}`;
  const embedUrl = `${window.location.origin}/embed/${imageId}`;
  const staticUrl = `${window.location.origin}/static/${imageId}`;

  const handleTooltipClick = (e, tooltip) => {
    e.stopPropagation();
    setSelectedTooltip(tooltip);
  };

  const handleCloseModal = () => {
    setSelectedTooltip(null);
    console.log("close");
  };

  useEffect(() => {
    const fetchImage = async () => {
      const data = await getImageWithTooltips(imageId);
      setImage(data);
      console.log("image data is", image);
    };
    fetchImage();
  }, [image, imageId]);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const handleCopyLink = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccessLink("Copied!");
      setTimeout(() => setCopySuccessLink(""), 2000);
    } catch (err) {
      setCopySuccessLink("Failed to copy!");
    }
  };

  const handleCopyEmbed = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccessEmbed("Copied!");
      setTimeout(() => setCopySuccessEmbed(""), 2000);
    } catch (err) {
      setCopySuccessEmbed("Failed to copy!");
    }
  };

  if (!image) return <p>Loading...</p>;

  const imageUrl = `${image.url}`;
  // const iframeCode = `<iframe src="${imageUrl}" width="600" height="400" frameborder="0" title="Image Preview"></iframe>`;
  // const iframeCode = `<div style="width: 100%;"><div style="position: relative; padding-bottom: 50%; padding-top: 0; height: 0;"><iframe title="Image Preview" frameborder="0" width="1200" height="600" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${imageUrl}" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div>`;
  // const iframeCode = `<div style="width: 100%;"><div style="position: relative; padding-bottom: 50%; padding-top: 0; height: 0;"><iframe title="Image Preview" frameborder="0" width="1200" height="600" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${shareableUrl}" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div>`;
  const iframeCode = `<div style="width: 100%;"><div style="position: relative; padding-bottom: 50%; padding-top: 0; height: 0;"><iframe title="Interactive Image" frameborder="0" width="1200" height="600" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${embedUrl}" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div>`;

  const handlePreview = () => {
    window.open(staticUrl, "_blank");
  };

  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <div>
        <nav className="navbar">
          {location.pathname !== "/home" && (
            <Link
              to="/home"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
              }}
            >
              {" "}
              Home
            </Link>
          )}
          {location.pathname !== "/upload" && (
            <Link
              to="/upload"
              style={{
                backgroundColor: "black",
                color: "white",
                borderRadius: "10px",
              }}
            >
              Upload
            </Link>
          )}
        </nav>
      </div>
      <div className="mainContainer">
        <div className="shareImgMain">
          <div
            style={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              display: "inline-block",
              height: "auto",
            }}
          >
            <img
              src={imageUrl}
              alt="Shared"
              style={{ width: "100%", objectFit: "contain", display: "block" }}
            />

            {image &&
              image.tooltips &&
              image.tooltips.map((tooltip, i) => (
                <div
                  key={i}
                  onClick={(e) => handleTooltipClick(e, tooltip)}
                  style={{
                    color: `${image.color}` || "black",
                    position: "absolute",
                    left: `${tooltip.x}%`,
                    top: `${tooltip.y}%`,
                    cursor: "pointer",
                    // transform: "translate(-50%, -50%)", // Center the icon on its position
                    width: "fit-content", // Ensure container fits icon
                    height: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize:
                        image.size === "small"
                          ? "1vw"
                          : image.size === "medium"
                          ? "1.5vw"
                          : image.size === "large"
                          ? "2vw"
                          : "1.5vw",
                      lineHeight: 1,
                      display: "block",
                    }}
                  >
                    {image.iconStyle || "radio_button_checked"}
                  </span>
                </div>
              ))}
          </div>
          {/* <div>
                <h3>Image Link</h3>
                <input type="text" readOnly value={imageUrl} style={{ width: '100%', marginBottom: '1rem' }} />
                <button 
                        onClick={() => handleCopy(imageUrl)}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Copy Link
                    </button>
            </div>
            <div>
                <h3>Embed Code</h3>
                <textarea readOnly value={iframeCode} style={{ width: '100%', height: '100px' }} />
                <button 
                        onClick={() => handleCopy(iframeCode)}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            alignSelf: 'flex-start'
                        }}
                    >
                        Copy Embed
                    </button>
            </div> */}
          <div className="share-section">
            <div className="input-group">
              <h3>Image Link</h3>
              <input type="text" readOnly value={staticUrl} />
              <button
                className="copy-button"
                onClick={() => handleCopyLink(staticUrl)}
              >
                Copy
              </button>
              <span
                className={`copy-popup ${copySuccessLink ? "visible" : ""}`}
              >
                {copySuccessLink}
              </span>
              <button className="copy-button" onClick={handlePreview}>
                Preview
              </button>
            </div>
          </div>

          <div className="share-section">
            <div className="input-group">
              <h3>Code Link</h3>
              <textarea
                readOnly
                value={`<div style="width: 100%;"><div style="position: relative; padding-bottom: 50%; padding-top: 0; height: 0;"><iframe id="myIframe" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" title="Interactive Image" frameborder="0" width="1200" height="600" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${window.location.origin}/embed/${imageId}" type="text/html" allowscriptaccess="always" allow="autoplay; fullscreen; xr-spatial-tracking" scrolling="yes" allownetworking="all" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div> </div>`}
              />
              <button
                className="copy-button"
                onClick={() =>
                  handleCopyEmbed(
                    `<div style="width: 100%;background-color:white"><div style="position: relative; padding-bottom: 50%; padding-top: 0; height: 0;"><iframe id="myIframe" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" title="Interactive Image" frameborder="0" width="1200" height="600" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${window.location.origin}/embed/${imageId}" type="text/html" allowscriptaccess="always" allow="autoplay; fullscreen; xr-spatial-tracking" scrolling="yes" allownetworking="all" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div> </div>`
                  )
                }
              >
                Copy Code
              </button>
              <span
                className={`copy-popup ${copySuccessEmbed ? "visible" : ""}`}
              >
                {copySuccessEmbed}
              </span>
            </div>
          </div>

          {selectedTooltip && (
            <TooltipModal
              tooltip={selectedTooltip}
              imageUrl={imageUrl}
              onClose={handleCloseModal}
              isEditMode={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ShareImage;
